var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { attrs: { justify: "center" } },
    [
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c("page-toolbar", {
            attrs: { "previous-page-label": "MTurk Top", title: "Assignments" },
            scopedSlots: _vm._u([
              {
                key: "contents",
                fn: function () {
                  return [
                    _c("approve-assignments-dialog-button", {
                      attrs: {
                        client: _vm.client,
                        "assignment-ids": _vm.assignmentIds,
                        "checked-assignment-ids": _vm.checkedAssignmentIds,
                      },
                      on: { complete: _vm.onComplete },
                    }),
                    _c("reject-assignments-dialog-button", {
                      attrs: {
                        client: _vm.client,
                        "assignment-ids": _vm.assignmentIds,
                        "checked-assignment-ids": _vm.checkedAssignmentIds,
                      },
                      on: { complete: _vm.onComplete },
                    }),
                    _c("send-bonus-dialog-button", {
                      attrs: {
                        client: _vm.client,
                        assignments: _vm.assignments,
                        "checked-assignments": _vm.checkedAssignments,
                      },
                      on: { complete: _vm.onComplete },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "11" } },
        [
          _c(
            "v-card",
            { attrs: { width: "500" } },
            [
              _c(
                "v-card-text",
                [
                  _vm._v(" Select Tutti HIT Batch first: "),
                  _c("v-select", {
                    attrs: {
                      "hide-details": "",
                      loading: _vm.loadingBatches,
                      items: _vm.batches,
                      "return-object": "",
                      "item-text": "name",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.listAssignmentsForTuttiHITBatch()
                      },
                    },
                    model: {
                      value: _vm.selectedBatch,
                      callback: function ($$v) {
                        _vm.selectedBatch = $$v
                      },
                      expression: "selectedBatch",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "11" } },
        [
          _vm.selectedBatch
            ? _c("v-data-table", {
                staticClass: "elevation-1",
                attrs: {
                  dense: "",
                  "sort-desc": "",
                  "show-select": "",
                  headers: _vm.headers,
                  items: _vm.assignments,
                  "item-key": "AssignmentId",
                  loading: _vm.loading,
                  "sort-by": "Timestamp",
                  "items-per-page": 100,
                  "footer-props": {
                    itemsPerPageOptions: [10, 30, 50, 100, 300],
                  },
                  search: _vm.searchQuery,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "progress",
                      fn: function () {
                        return [
                          _c("v-progress-linear", {
                            staticClass: "px-0",
                            attrs: {
                              stream: "",
                              "buffer-value": "0",
                              value: _vm.progress,
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "top",
                      fn: function () {
                        return [
                          _c(
                            "v-card-title",
                            [
                              _vm._v(
                                " Assignments for " +
                                  _vm._s(_vm.selectedBatch.name) +
                                  " "
                              ),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ attrs, on }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      icon: "",
                                                      loading: _vm.loading,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.listAssignmentsForTuttiHITBatch(
                                                          false
                                                        )
                                                      },
                                                    },
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c("v-icon", [
                                                  _vm._v("mdi-refresh"),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    261557800
                                  ),
                                },
                                [
                                  _c("p", { staticClass: "text-center ma-0" }, [
                                    _vm._v("Refresh the whole table"),
                                    _c("br"),
                                    _vm._v(
                                      "(Note: this may take minutes to complete,"
                                    ),
                                    _c("br"),
                                    _vm._v("depending on the number of HITs)"),
                                  ]),
                                ]
                              ),
                              _c("v-spacer"),
                              _c("v-text-field", {
                                attrs: {
                                  "single-line": "",
                                  "hide-details": "",
                                  "append-icon": "mdi-magnify",
                                  label: "Search",
                                },
                                model: {
                                  value: _vm.searchQuery,
                                  callback: function ($$v) {
                                    _vm.searchQuery = $$v
                                  },
                                  expression: "searchQuery",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("v-card-text", [
                            _vm._v(
                              " (Last updated at: " +
                                _vm._s(_vm.lastCachedAt) +
                                ") "
                            ),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "item.WorkerId",
                      fn: function ({ item }) {
                        return [_vm._v(" " + _vm._s(`${item.WorkerId}`) + " ")]
                      },
                    },
                    {
                      key: "item.AssignmentId",
                      fn: function ({ item }) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "text-truncate",
                              staticStyle: { "max-width": "100px" },
                            },
                            [_vm._v(" " + _vm._s(item.AssignmentId) + " ")]
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.AcceptTime",
                      fn: function ({ item }) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.standardDateTimeFormat(item.AcceptTime)
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.SubmitTime",
                      fn: function ({ item }) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.standardDateTimeFormat(item.SubmitTime)
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.AutoApprovalTime",
                      fn: function ({ item }) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.standardDateTimeFormat(
                                  item.AutoApprovalTime
                                )
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3738799082
                ),
                model: {
                  value: _vm.checkedAssignments,
                  callback: function ($$v) {
                    _vm.checkedAssignments = $$v
                  },
                  expression: "checkedAssignments",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }